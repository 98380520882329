/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@tanstack/react-query';
import CustomButton from '../../reuseable/CustomButton';
import { ButtonContainer } from './Button';
import { buttonContainerConfig } from '../../propsConfig';
import { setButtonAnalytics } from '../../../api/analytics';

const useStyles = makeStyles((theme) => ({
  buttonClassName: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '1.8rem !important',
    paddingRight: '1.8rem !important',
    textTransform: 'capitalize',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    '&:hover': { background: '#E5E5EA' },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 'calc(0.5rem * 0.75)',
      paddingBottom: 'calc(0.5rem * 0.75)',
      paddingLeft: 'calc(1.8rem * 0.75) !important',
      paddingRight: 'calc(1.8rem * 0.75) !important',
    },
  },
  imageContainerClassName: {
    height: '1.75rem',
    weight: '1.75rem',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(1.75rem * 0.75)',
      weight: 'calc(1.75rem * 0.75)',
    },
  },
  imageClassName: {
    borderRadius: '0px',
  },
  buttonContainerClassName: {
    backgroundColor: 'transparent !important',
    gap: '12px',
    filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, 0.12))',
    [theme.breakpoints.up('sm')]: {
      gap: 'calc(12px * 0.75)',
    },
  },
}));

export default function Social({
  buttonChild,
  containerClassName,
  containerStyling,
}) {
  const classes = useStyles();
  const mutation = useMutation({
    mutationFn: (id) => setButtonAnalytics(id),
  });

  const buttonConfig = buttonChild?.map((buttonBody) => ({
    key: buttonBody?.id,
    buttonProps: {
      onClick: () => {
        mutation.mutate(buttonBody.id);
        window.open(`${buttonBody.url}`, '_blank');
      },
    },
    buttonTitle: buttonBody?.provider,
    buttonClassName: classes.buttonClassName,
    buttonStyling: {
      backgroundColor: 'none',
      buttonPlacement: 'center',
      buttonSize: 'custom',
      borderRadius: '8px',
      borderColor: 'none',
      alignment: 'center',
      color: '#201C1D',
      fontSize: 15,
      fontWeight: 'bold',
    },
    buttonIcon: buttonBody?.provider,
    buttonIconType: 'socials',
    buttonIconStyling: {
      buttonIconPlacement: 'start',
    },
    moreIconProps: {
      imageContainerClassName: classes.imageContainerClassName,
      imageClassName: classes.imageClassName,
    },
  }));

  const containerProps = `${classes.buttonContainerClassName} ${containerClassName}`;
  const buttonsDirection = 'vertical';

  return (
    <ButtonContainer
      {...buttonContainerConfig(
        containerProps,
        containerStyling,
        buttonsDirection,
      )}
    >
      {buttonConfig?.map((buttonBody) => (
        <CustomButton {...buttonBody} />
      ))}
    </ButtonContainer>
  );
}
