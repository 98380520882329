import React, { Suspense, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import BiziMicrosite from './BiziMicrosite';
import Loading from '../../components/loading/Loading';

export default function Microsite() {
  const { pathname } = window.location;
  const pathnameUrl = pathname.split('/')[1];
  const queryClient = useQueryClient();

  // Hydrate the query client with initial data from the server
  /* eslint-disable no-underscore-dangle */
  useEffect(() => {
    if (window.__INITIAL_DATA__) {
      queryClient.setQueryData(
        ['microsite', pathnameUrl],
        window.__INITIAL_DATA__,
      );
    }
  }, [pathnameUrl, queryClient]);
  /* eslint-enable no-underscore-dangle */

  return (
    <Suspense fallback={<Loading />}>
      {/* eslint-disable-next-line no-underscore-dangle */}
      <BiziMicrosite data={window.__INITIAL_DATA__} />
    </Suspense>
  );
}
