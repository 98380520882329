import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonContainer } from './Button';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomBusinessCard from '../../reuseable/CustomBusinessCard';
import CustomButton from '../../reuseable/CustomButton';
import {
  buttonConfig,
  buttonContainerConfig,
  containerConfig,
} from '../../propsConfig';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 !important',
    gap: '0 !important',
    filter: 'unset !important',
    background: 'unset !important',
    border: 'unset !important',
  },
  btnPadding: {
    marginTop: '0 !important',
    padding: '10px',
    [theme.breakpoints.up('sm')]: { padding: '5px !important' },
  },
}));

export default function BusinessCard({
  cardChild,
  buttonsDirection,
  buttonChild,
  containerClassName,
  containerStyling,
}) {
  const classes = useStyles();

  const cardConfig = {
    id: cardChild?.id,
    type: cardChild?.type,
    email: cardChild?.email,
    name: cardChild?.name,
    phone: cardChild?.phone
      ? cardChild.phone.country_code + cardChild.phone.phone_number
      : '',
    about: cardChild?.about,
    image: cardChild?.image,
    website: cardChild?.website,
    designation: cardChild?.designation,
    business_type: cardChild?.business_type,
    business_address: cardChild?.business_address,
  };

  const moreContainerConfig = `${classes.btnPadding} ${containerClassName}`;

  return (
    <CustomContainer
      {...containerConfig(moreContainerConfig, containerStyling)}
    >
      <CustomBusinessCard {...cardConfig} />
      <ButtonContainer
        {...buttonContainerConfig(
          classes.container,
          containerStyling,
          buttonsDirection,
        )}
      >
        {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
          <CustomButton {...buttonBody} />
        ))}
      </ButtonContainer>
    </CustomContainer>
  );
}
