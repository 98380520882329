import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { containerConfig } from '../../components/propsConfig';

import CustomContainer from '../../components/reuseable/CustomContainer';
import ProfileIcon from '../../assets/icons/user_round.svg';
import {
  EmailIcon,
  CallIcon,
  LocationIcon,
  UrlIcon,
} from '../../components/icons/ReactIcons';

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '21px',
    marginBottom: '21px',
  },
  headerImg: {
    minWidth: '60px',
    height: '60px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  name: {
    color: '#333',
    fontSize: '23px',
    fontWeight: 600,
    margin: '0',
  },
  designation: {
    color: '#8E8E93',
    fontWeight: 500,
    fontSize: '15px',
    margin: '0',
  },
  aboutMeWrapper: {
    marginBottom: '21px',
  },
  sectionTitle: {
    fontSize: '21px',
    fontWeight: 500,
    margin: '0 0 15px',
  },
  aboutMeDescription: {
    color: '#636366',
    fontSize: '15px',
    margin: '0',
  },
  hr: {
    opacity: 0.3,
    margin: '21px 0 14px',
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '11px',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  contactItemDetails: {
    margin: 0,
    fontSize: '15px',
    color: '#636366',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default function BiziBusinessCardDetails({ data }) {
  console.log('data', data);

  const businessAddress = data.business_address;

  const classes = useStyles();
  return (
    <CustomContainer {...containerConfig()}>
      <div className={classes.headerWrapper}>
        <img
          className={classes.headerImg}
          src={data.image ? data.image?.url : ProfileIcon}
          style={{ objectFit: 'cover' }}
          alt={data.image?.id}
        />

        <div>
          <h3 className={classes.name}>{data.name}</h3>
          <p className={classes.designation}>{data.designation}</p>
        </div>
      </div>

      <div className={classes.aboutMeWrapper}>
        <h4 className={classes.sectionTitle}>About Me</h4>
        <p className={classes.aboutMeDescription}>{data.about || '-'}</p>
      </div>

      <hr className={classes.hr} />

      <div className={classes.contactWrapper}>
        <h4 className={classes.sectionTitle}>Contact</h4>

        <div className={classes.contactDetails}>
          {data.email && (
            <div className={classes.contactItem}>
              <EmailIcon style={{ minWidth: '20px' }} />
              <a
                href={`mailto:${data.email}`}
                className={classes.contactItemDetails}
              >
                {data.email}
              </a>
            </div>
          )}

          {data.phone && (
            <div className={classes.contactItem}>
              <CallIcon style={{ minWidth: '20px' }} />
              <a
                href={`tel:${data.phone.country_code}${data.phone.phone_number}`}
                className={classes.contactItemDetails}
              >
                {data.phone.country_code}
                {data.phone.phone_number}
              </a>
            </div>
          )}

          {businessAddress && (
            <div className={classes.contactItem}>
              <LocationIcon style={{ minWidth: '20px' }} />
              {typeof businessAddress === 'string' ? (
                <p className={classes.contactItemDetails}>{businessAddress}</p>
              ) : (
                <p className={classes.contactItemDetails}>
                  {businessAddress.address_line1 && (
                    <>
                      {businessAddress.address_line1}
                      {', '}
                    </>
                  )}
                  {businessAddress.address_line2 && (
                    <>
                      {businessAddress.address_line2}
                      {', '}
                    </>
                  )}
                  {businessAddress.city && (
                    <>
                      {businessAddress.city}
                      {', '}
                    </>
                  )}
                  {businessAddress.state && (
                    <>
                      {businessAddress.state}
                      {', '}
                    </>
                  )}
                  {businessAddress.postal_code && (
                    <>
                      {businessAddress.postal_code}
                      {', '}
                    </>
                  )}
                  {businessAddress.country && (
                    <span>{businessAddress.country}</span>
                  )}
                </p>
              )}
            </div>
          )}

          {data.website && (
            <div className={classes.contactItem}>
              <UrlIcon style={{ minWidth: '20px' }} />
              <a
                href={
                  data.website.startsWith('http')
                    ? data.website
                    : `http://${data.website}`
                }
                target="_blank"
                rel="noopener noreferrer"
                className={classes.contactItemDetails}
              >
                {data.website}
              </a>
            </div>
          )}
        </div>
      </div>
    </CustomContainer>
  );
}
